// Dependencies
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore/lite';
// Firebase db
import { db, auth } from '../../firebase';
// Types
import { StepsTypes } from '../types';

export const saveStepDataFn = (data) => async (dispatch, getState) => {
  try {
    const { steps: { steps } } = getState();

    const newStepsList = steps;

    const newData = {
      ...data,
      uid: auth.currentUser.uid,
    };
    await addDoc(collection(db, 'steps'), newData);
    newStepsList.push(newData);

    // All Ok
    dispatch({
      type: StepsTypes.saveStepData,
      payload: newStepsList,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getStepUserFn = () => async (dispatch) => {
  const q = query(collection(db, 'steps'), where('uid', '==', auth.currentUser.uid));

  const querySnapshot = await getDocs(q);
  const dataDB = querySnapshot.docs.map((doc) => doc.data());

  const step = dataDB.length;

  dispatch({
    type: StepsTypes.getStepUser,
    payload: step + 1,
  });
};
