// Dependencies
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { authenticateUserFn, logoutUserFn, userDataFn } from './redux/actions';
// Routes
import { Navigation } from './routes';
// Firebase auth
import { auth } from './firebase';

function App() {
  // Hooks
  const dispatch = useDispatch();
  // Observer user login
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(authenticateUserFn());
        const {
          email, displayName, photoURL, uid,
        } = user;
        dispatch(userDataFn({
          email,
          photoURL,
          displayName,
          uid,
        }));
      } else {
        dispatch(logoutUserFn());
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Navigation />
  );
}

export default App;
