// Types
import { UserTypes } from '../types';

const INITIAL_STATE = {
  data: null,
};

// eslint-disable-next-line default-param-last
export const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.getUserData:
      return {
        ...state,
        data: action.payload,
      };
    case UserTypes.userData:
      return {
        ...state,
        data: action.payload,
      };
    case UserTypes.setCountryToUser:
      return {
        ...state,
        data: {
          ...state.data,
          country: action.payload,
        },
      };
    default:
      return state;
  }
};
