// Types
import { AuthTypes } from '../types';

export const authenticateUserFn = () => (dispatch) => {
  dispatch({
    type: AuthTypes.authenticateUser,
    payload: true,
  });
};

export const logoutUserFn = () => (dispatch) => {
  dispatch({
    type: AuthTypes.logoutUser,
  });
};
