// Types
import { AuthTypes } from '../types';

const INITIAL_STATE = {
  isAuth: false,
};

// eslint-disable-next-line default-param-last
export const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.authenticateUser:
      return {
        ...state,
        isAuth: action.payload,
      };
    case AuthTypes.logoutUser:
      return {
        ...state,
        isAuth: false,
      };
    default:
      return state;
  }
};
