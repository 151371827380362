// Dependencies
import { collection, getDocs, addDoc } from 'firebase/firestore/lite';
// Types
import { UserTypes } from '../types';
// Firebase db
import { db } from '../../firebase';

export const getUserData = () => async (dispatch) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const dataDB = querySnapshot.docs.map((doc) => doc.data());

    dispatch({
      type: UserTypes.getUserData,
      payload: dataDB,
    });
  } catch (errorServer) {
    console.log(errorServer);
  }
};

export const userDataFn = (data) => (dispatch) => {
  dispatch({
    type: UserTypes.userData,
    payload: data,
  });
};

export const setCountryToUser = ({ country }) => async (dispatch, getState) => {
  const { user: { data: { uid } } } = getState();
  try {
    await addDoc(collection(db, 'countries'), {
      country,
      userUid: uid,
    });

    dispatch({
      type: UserTypes.setCountryToUser,
      payload: country,
    });
  } catch (error) {
    console.log(error);
  }
};
