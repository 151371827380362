import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: 'AIzaSyBPVdWNIZFJQROo-M0JnDLh3gWMwvkvTiY',
  authDomain: 'navigation-f0867.firebaseapp.com',
  projectId: 'navigation-f0867',
  storageBucket: 'navigation-f0867.appspot.com',
  messagingSenderId: '1042835926673',
  appId: '1:1042835926673:web:c2355a1514173860ba9930',
  measurementId: 'G-FFCS02SFVP',
};

// Initialize Firebase
// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
